import { Controller } from 'stimulus';

export default class WeightboardController extends Controller {
  static targets = ['video', 'playButton'];
  static values = { id: String };

  connect() {
    this.playCreated = false;
  }

  trackVideoPlay() {
    this.playButtonTarget.style.display = 'none';
    this.videoTarget.play();
    // Don't track the play event if it has already been tracked
    if (this.playCreated) {
      return;
    }

    const xhr = new XMLHttpRequest();
    const id = this.element.dataset.id;
    xhr.open('POST', `/feeder_flash_videos/${id}/create_play`, true);
    xhr.setRequestHeader(
      'X-CSRF-Token',
      document.getElementsByName('csrf-token')[0].content
    );
    xhr.send();
    this.playCreated = true;
  }
}
